.sidebarOptions {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
}

.sidebarOptions:hover {
  background-color: #e8f5fe;
  border-radius: 30px;
  color: var(--twitter-color);
  transition: color 100ms ease-out;
}

.sidebarOptions > .MuiSvgIcon-root {
  padding: 10px 15px;
}

.sidebarOptions > h2 {
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;
}

.done_icon {
  color: var(--twitter-color) !important;
}

@media screen and (max-width: 950px) {
  /* .sidebar__tweet {
    display: none !important;
  } */

  .sidebarOptions h2 {
    display: none;
  }
}
