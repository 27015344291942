.conversation__page {
  position: relative;
}

.messagepage-heading-4 {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--twitter-background);
}

.messagepage_heading_child_1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.messagepage_heading_child_1 p {
  margin-left: 1rem;
  cursor: pointer;
}

.messagepage_heading_child_2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 0.7rem;
}

.message_video_call {
  font-size: 1.5rem;
  color: var(--twitter-color);
  cursor: pointer;
}

.message__buttonContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: var(--twitter-background);
  border-radius: 0.7rem;
}

.message__buttonContainer input {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  padding: 0 20px;
  margin: 0;
  background-color: transparent;
}

.message__button {
  height: 50px;
  width: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0.5rem;
  color: var(--twitter-color);
}

.message__page_messages {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 3.1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.message__page_messages::-webkit-scrollbar {
  width: 0rem;
}

.message__intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  gap: 0.3rem;
  margin-bottom: 0.8rem;
}

.message__container1 {
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.message__container2 {
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5rem;
}

.message__child1,
.message__child2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.07rem 1.6rem;
  border-radius: 1.5rem;
}

.message__child1 {
  align-items: flex-end;
  margin-left: 1rem;
  background-color: var(--twitter-color);
}

.message__child2 {
  align-items: flex-start;
  margin-right: 1rem;
  background-color: var(--twitter-background);
}

.groupmessage__container2 {
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 0.5rem;
  gap: 0.3rem;
}

.groupmessage__child2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.07rem 1.6rem;
  border-radius: 1.5rem;
  border-top-left-radius: 0;
}

.groupmessage__child2 {
  align-items: flex-start;
  margin-right: 1rem;
  background-color: var(--twitter-background);
}

.create_Group_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 3.5rem 1rem 0;
}

.create_Group_button button {
  width: 100%;
  height: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--twitter-color);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.create_Group_button button:hover {
  background-color: #1a91da;
}

.crete_group_container {
  background-color: white;
  width: 90vw;
  height: 50rem;
  max-width: 30rem;
  max-height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  position: relative;
}

.crete_group_container h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
}

.crete_group_container form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
}

.crete_group_container input {
  width: 90%;
  outline: none;
  /* padding: 0 1rem; */
  padding: 0 0.9rem;
  height: 3rem;
  border: 2px solid var(--twitter-background);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.crete_group_container input::placeholder {
  font-size: 1.2rem;
  font-weight: 600;
}

.crete_group_container input[type="file"] {
  position: absolute;
  top: -1.3rem;
  left: 0rem;
  opacity: 0;
}

.crete_group_container .group_span {
  height: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--twitter-color);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
}

.crete_group_container .upload_icon {
  font-size: 1.5rem;
}

.crete_group_container button {
  height: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--twitter-color);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 1rem;
}

.crete_group_container button:hover {
  background-color: #1a91da;
}

.create_group_close_icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: var(--twitter-color);
  cursor: pointer;
}

.group__info {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: var(--twitter-background); */
  background-color: white;
  display: flex;
  z-index: 100;
  flex-direction: column;
  align-content: center;
}

.group__info__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.group__info__header {
  width: 100%;
  /* height: 3rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--twitter-background);
  position: relative;
}

.group__info__header .exit_group {
  position: absolute;
  top: 0rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.group__info__header .group_photo_container {
  width: 11rem;
  height: 11rem;
  border-radius: 100%;
  position: relative;
}

.group__info__header__avatar {
  width: 11rem;
  height: 11rem;
  border-radius: 100%;
}

.group__info__header .select_groupphoto_info {
  width: 11rem;
  height: 11rem;
  border-radius: 100%;
  position: absolute;
  top: -1.2rem;
  opacity: 0;
  z-index: 10;
}

.group__info__header .create_group_icon {
  position: absolute;
  top: 0;
  right: 0.2rem;
}

.group__info__header h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.edit_group_name_info {
  display: flex;
  position: relative;
  margin: 0.8rem 0;
}

.edit_group_name_info input {
  width: 100%;
  height: 3rem;
  border: 0.1rem black solid;
  /* padding: 0 1rem; */
  padding: 0 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.edit_group_name_info input::placeholder {
  font-size: 1.2rem;
  font-weight: 600;
}

.edit_group_name_info .done_icon {
  height: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.5rem;
  color: white;
  font-size: 2rem;
  position: absolute;
  font-weight: 600;
  right: 0.3rem;
}

.group__info__body {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.group_info_body_h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
}

.group_info_body_p {
  margin-top: 0.5rem;
}

.group__info__footer {
  width: 90%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.group_info_member_h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.group__info__footer__members {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.group__info__footer__members__member {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background-color: var(--twitter-background);
  justify-content: space-between;
}

.group__info__footer__members__member_child {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.group__info__footer__members__member .admin__edit__member {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.group__info__footer__members__member .admin__edit__member button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--twitter-color);
  font-size: 1.2rem;
  font-weight: 600;
}

.group__info__footer__members__member .admin__edit__member button:hover {
  text-decoration: underline;
}

.group_info_addmember {
  width: 100%;
  height: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--twitter-color);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
  margin: 0;
}

.adding_member_container {
  max-width: 40rem;
  width: 90vw;
  max-height: 40rem;
  height: 90vh;
  min-height: 30rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  border: 0;
  position: relative;
}

.adding_member_container input {
  width: 82%;
  padding: 0 0.9rem;
  height: 3rem;
  border: 2px solid var(--twitter-background);
  outline: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.adding_member_container ul {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  width: 90%;
  gap: 0.3rem;
}

.adding_member_container li {
  list-style: none;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background-color: var(--twitter-background);
  justify-content: space-between;
}

.adding_member_container span {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.adding__member__detail {
  display: flex;
  align-items: center;
}

.adding_member_container li button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--twitter-color);
  font-size: 1.2rem;
  font-weight: 600;
}

.group_conversation_container {
  position: relative;
  width: 80%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.group_conversation_container li {
  width: 100%;
}

.search__button_message {
  color: var(--twitter-color);
  position: absolute;
  right: 1rem;
  top: 1.2rem;
}

.videocall_page {
  /* max-width: 1300px; */
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  overflow: hidden;
  background-color: black;
}

.videocall_page_p2 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

/* .videocall_page_child {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  background-color: transparent;
} */

.video_containers {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 0.5rem;
}

.video_containers video {
  width: 100%;
  aspect-ratio: 16/9;
}

/* .myself_video_active {
  width: 16rem;
  align-self: 16/9;
} */

.video_containers .video_container {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.video_containers .video_container .video_user_detail {
  position: absolute;
  top: 0;
  left: 0;
}

.video_container .video_name {
  height: 2.3rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.videocall_page_child_2 {
  /* height: 100%; */
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.videocall_page_child_2 button {
  padding: 0.3rem 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.3rem;
  background-color: var(--twitter-color);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
}

.videocall_page_child_2 button:hover {
  background-color: #1a91da;
}

.videocall_page_child_2 svg {
  font-size: 2.4rem;
  color: var(--twitter-color);
  cursor: pointer;
}

.videocall_page_child_2 .mute {
  color: #ff0000;
}

.videocall_page_child_2 .end_call {
  color: red;
}

.videocall_page_child_2 .end_call:hover {
  color: #ff0000;
}

@media screen and (max-width: 950px) {
  .conversation__page {
    flex: 1;
  }

  .group_conversation_container {
    width: 96%;
  }
}

@media screen and (max-width: 600px) {
  .messagepage_heading_child_1 p {
    margin: 0;
  }
}

.close_videocall{
  position: fixed;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: var(--twitter-color);
  cursor: pointer;
  z-index: 100;
}