.widgets {
  flex: 0.3;
  overflow-y: scroll;
}

.widgets::-webkit-scrollbar {
  display: none;
}

.widgets__input {
  display: flex;
  align-items: center;
  background-color: var(--twitter-background);
  padding: 10px 20px;
  border-radius: 50px;
  margin-top: 10px;
  margin-left: 20px;
}

.widgets__input>input {
  border: none;
  margin-top: 0px;
  background-color: var(--twitter-background);
}

.widgets__searchIcon {
  color: gray;
}

.widgets__widgetContainer {
  margin-top: 15px;
  margin-left: 20px;
  padding: 20px;
  background-color: #f5f8fa;
  border-radius: 20px;
}

.widgets__widgetContainer>h2 {
  font-size: 18px;
  font-weight: 800;
}

@media screen and (max-width:1300px) {
  .widgets {
    display: none;
  }
}