.tweetBox {
  padding-bottom: 10px;
  border-bottom: 8px solid var(--twitter-background);
  padding-right: 10px;
}

.tweetReplyBox {
  padding-bottom: 10px;
  padding-right: 10px;
  border: 0;
}

.tweetBox > form {
  display: flex;
  flex-direction: column;
}

.tweetReplyBox > form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tweetBox__input {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  /* justify-content: flex-start; */
}

.tweetBox__input > textarea {
  flex: 1;
  resize: none;
  margin-left: 15px;
  font-size: 20px;
  border: none;
  margin-top: 0px;
  height: 11rem;
}

.tweetBox__input > input {
  flex: 1;
  margin-left: 15px;
  font-size: 20px;
  border: none;
  margin-top: 0px;
}

.tweetBox__imageInput {
  border: none;
  padding: 5px 20px;
}

.imageIcon_tweetButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tweetBox__tweetButton {
  background-color: var(--twitter-color) !important;
  border: none !important;
  color: white !important;
  font-weight: 700 !important;
  text-transform: inherit !important;
  border-radius: 50px !important;
  width: 100px;
  height: 40px !important;
}

.imageIcon {
  color: var(--twitter-color);
  padding-left: 25px;
}

.imageInput {
  display: none;
}
