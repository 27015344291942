.otherProfile__page {
  flex: 0.9;
  border-right: 1px solid var(--twitter-background);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.otherProfile__page::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 950px) {
  .otherProfile__page {
    flex: 1;
  }
}
