.coverImage {
  /* height: 200px;
  width: 600px; */
  height: 13.5rem;
  width: 100%;
  object-fit: cover;
}

.coverImageContainer {
  position: relative;
}

.hoverCoverImage {
  position: absolute;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.603);
  width: 100%;
  height: 98%;
  opacity: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverCoverImage:hover {
  opacity: 100%;
}

.photoIcon {
  color: white;
  font-size: 35px !important;
  opacity: 40%;
  margin-right: 20px;
  cursor: pointer;
}

.photoIcon:hover {
  opacity: 100%;
}

.photoIconDisabled {
  cursor: not-allowed;
}

.userInfo {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.heading-4 {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--twitter-background);
}

.arrow-icon {
  padding: 1rem;
  cursor: pointer;
}

.arrow-icon:hover {
  color: var(--twitter-color);
}

.heading-3 {
  margin-left: 20px;
  font-size: 24px;
}

.userNameSection {
  margin-left: 20px;
  color: darkgrey;
}

.mainprofile {
  background-color: rgb(207, 206, 206);
  margin-top: 0;
  border: 1 px solid white;
}

.profile-bio {
  height: 100px;
}

.avatarContainer {
  margin-top: -80px;
  margin-left: 20px;
  border-radius: 100%;
  position: relative;
  height: 150px;
  width: 150px;
  border: 4px solid white;
}

.avatar {
  position: relative;
  width: 100%;
  height: 98%;
  border-radius: 100%;
}

.hoverAvatarImage {
  position: absolute;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.603);
  width: 100%;
  height: 98%;
  border-radius: 100%;
  opacity: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverAvatarImage:hover {
  opacity: 100%;
}

.Edit-profile-btn {
  background-color: white;
  padding: 10px;
  border: 1px solid;
  border-radius: 2rem;
  margin-right: 20px;
}

.Edit-profile-btn:hover {
  background-color: rgb(207, 206, 206);
}

.Tweet {
  background-color: white;
  width: 20%;
  height: 20%;
  padding: 2rem 1rem;
  color: skyblue;
}

.tweetsText {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.infoContainer {
  margin-left: 20px;
  margin-top: 10px;
}

.locationAndLink {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.subInfo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.link {
  color: var(--twitter-color);
  cursor: pointer;
}

.following,
.followers {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.following:hover,
.followers:hover {
  text-decoration: underline;
}

.following p,
.followers p {
  font-weight: bold;
}

.followingText,
.followersText {
  font-weight: lighter;
}