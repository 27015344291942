.login-container {
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.login-container::-webkit-scrollbar {
  display: none;
}

.form-container {
  /* flex: 1; */
  padding: 4rem;
  /* margin: 10px; */
  /* margin-left: -50px; */
  width: 86vw;
  max-width: 28rem;
  align-content: center;
}

.form-box {
  justify-content: left;
  align-items: inherit;
  grid-row: auto;
}

.Twitter-img {
  color: skyblue;
  max-width: 50%;
}

.input-form {
  width: 20px;
}

.heading,
h1 {
  font-size: 50px;
}

.heading1,
h3 {
  font-size: 35px;

  justify-content: end;
}

.image-container {
  /* flex: 2; */
  /* margin-left: -100px; */
}

.image {
  max-width: 100%;
  height: 100%;
  /* margin-left: -50px; */
}

input {
  display: grid;
  margin-bottom: 1px;
  padding: 5px;
  background-color: white;
  margin-top: 20px;
}

.userName,
.name,
.email,
.password {
  background-color: #e8f0fe;
  width: 90%;
  max-width: 20rem;
  border-radius: 8px;
  padding: 14px;
  border: none;
  outline: none;
}

.display-name {
  background-color: #e8f0fe;
  width: 70%;
  border-radius: 8px;
  padding: 14px;
  border: none;
  outline: none;
}

.btn {
  background-color: rgb(92, 199, 241);
  width: 100%;
  max-width: 21.7rem;
  padding: 14px;
  border-radius: 8px;
  font-size: 17px;
  border: none;
  outline: none;
  margin-bottom: 20px;
  margin-top: 15px;
}

.g-btn {
  border: 1px solid;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
  width: 100% !important;
  max-width: 20rem;
  margin: 10px;
}

.google-button {
  margin-top: 5%;
}

.g-btn > div,
.g-btn > div > svg {
  height: 30px !important;
}

.errorMessage {
  color: red;
  font-weight: 500;
  margin: 10px 0;
}

.signUpText > Link {
  text-decoration: none;
  color: var(--twitter-color) !important;
}

@media screen and (max-width: 950px){
  .image-container {
    display: none;
  }
}

@media screen and (max-width: 688px) {
  .login-container {
    order: 2;
    display: flex;
    flex-direction: column;
    }

  .form-container {
    padding: 1.4rem;
  }
}
