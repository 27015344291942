.notifications__page {
  flex: 0.9;
  border-right: 1px solid var(--twitter-background);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notifications__page::-webkit-scrollbar {
  display: none;
}

.notification__ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.notification__ul::-webkit-scrollbar {
  display: none;
}

.notification__ul > li {
  border-bottom: 1px solid var(--twitter-background);
  padding: 1rem 0;
  width: 90%;
  position: relative;
}

.notification__likeContainer1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  max-height: 10.4rem;
  overflow: ellipsis;
  overflow: hidden;
}

.notification__likeIcon {
  color: rgba(249, 24, 128, 1);
  padding: 0.4rem 0.7rem 0.7rem 0.7rem;
}

.notification__followIcon {
  color: rgba(29, 161, 242, 1);
  padding: 0.4rem 0.7rem 0.7rem 0.7rem;
}

.notification__replyIcon {
  margin: 0.4rem 0.7rem 0.7rem 0.7rem;
}

.notification__likeContainer:hover {
  background-color: var(--twitter-background);
  cursor: pointer;
}

.notification__likeContainer2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notification__name {
  font-weight: bold !important;
}

.notification__text {
  font-weight: 200;
}

.notification__tweet {
  font-weight: 200;
  color: grey;
}

.notification__tweetTitle {
  margin: 0;
}

.notification__time {
  position: absolute;
  font-weight: 900;
  color: grey;
  font-size: 0.8rem;
  right: 0.2rem;
  top: 0.2rem;
}

/* .notification__tweetMedia {
  max-width: 10rem;
  width: 90%;
  border-radius: 1rem;
  margin-top: 0.5rem;
} */

@media screen and (max-width: 950px) {
  .notifications__page {
    flex: 1;
  }
}

@media screen and (max-width: 600px) {
  .notification__likeContainer1 {
    max-height: 100%;
  }
}
