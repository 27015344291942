.feed {
  flex: 0.9;
  border-right: 1px solid var(--twitter-background);
  overflow-y: scroll;
  scrollbar-width: none;
}

.feed::-webkit-scrollbar {
  display: none;
}

.feed__header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
}

.feed__header > h2 {
  font-size: 20px;
  font-weight: 800;
}

@media screen and (max-width: 950px) {
  .feed {
    flex: 1;
  }
}