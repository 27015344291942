body {
  --twitter-color: #50b7f5;
  --twitter-background: #e6ecf0;
}

#ParentApp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: black; */
  height: 100vh;
  width: 100vw;
}

.app {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 1300px;
}

@media screen and (max-width: 668px) {
  .app {
    display: block;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

.modal {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}
