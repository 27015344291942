.tweet {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--twitter-background);
  padding-bottom: 10px;
  /* max-width: ; */
}

.tweet__body {
  flex: 1;
  padding: 10px;
}

.tweet__body > img {
  border-radius: 20px;
  width: 50vw;
  max-width: 30rem;
}

.tweet__footer {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 10px;
}

.tweet__footer__icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tweet__footer__icon p{
  margin: 0;
}

.tweet__footer__icon:hover {
  cursor: pointer;
  color: var(--twitter-color);
}

.tweet__headerDescription {
  margin-bottom: 10px;
  font-size: 15px;
}

.tweet__headerText > h3 {
  font-size: 15px;
  margin-bottom: 5px;
}

.tweet__badge {
  font-size: 14px !important;
  color: var(--twitter-color);
}

.tweet__headerSpecial {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}

.tweet__avatar {
  padding: 20px;
  cursor: pointer;
}

.tweet__img1 {
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid var(--twitter-background);
}

.tweet__img2 {
  width: 20rem;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid var(--twitter-background);
}

.tweet__img3 {
  width: 15rem;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid var(--twitter-background);
}

.tweet__img4 {
  width: 10rem;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid var(--twitter-background);
}

@media screen and (max-width: 700px){
  .tweet__body > img{
    border-radius: 0.8rem;
  }
}