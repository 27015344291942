.modal {
  background-color: white;
  padding: 10px;
  overflow-y: scroll;
}

.modal::-webkit-scrollbar {
  display: none;
}

.header {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  z-index: 1;
  background-color: white;
  top: 0px;
  position: sticky;
}

.header>.header-title {
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
}

.header>.save-btn {
  margin-right: 10px;
  color: aliceblue;
  background-color: black;
  padding: 5px 20px;
  border-radius: 20px;

}

.header__twittericon {
  display: flex;
  flex-direction: row;
  z-index: 1;
  background-color: white;
  top: 0px;
  position: sticky;
  width: 30px;
}

.header__twittericon>.twitterIcon {
  color: var(--twitter-color);
  font-size: 35px !important;
  margin-left: 250px;
}

.backgroundImage {
  height: 200px;
  width: 100%;
  margin-top: 5px;
  ;
  background-color: #b4b7b9;
  margin-bottom: 5px;
}

.profileTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
}

.profileImage {
  width: 134px;
  height: 134px;
  margin-top: -80px;
  border-radius: 9999px;
  padding: 0px;
  background-color: var(--main-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileImage>.MuiAvatar-root {
  width: 134px;
  height: 134px;
  margin-left: 10px;
}

.fill-content {
  margin: 20px;
}

.fill-content>.text-field {
  padding-bottom: 10px;
  border: none;

}

.privacy-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
}

.birthdate-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-left: 20px;
}

.birthdate-section p {
  font-weight: 600;
  padding-left: 5px;
  margin-bottom: -8px;
}

.birthdate-section text {
  color: rgb(135, 214, 250);
  cursor: pointer;
  padding-bottom: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  justify-content: center;
}

.text>.e-button {
  margin: 10px;
  color: rgb(255, 255, 255);
  background-color: black;
  border-radius: 20px;
}

.last-section h2 {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.last-section>.last-btn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.last-section>.last-btn:hover {
  background-color: #b4b7b9
}